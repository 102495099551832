import React from "react";

const AboutEnglisch = () => {
  return (
    <div class="about">
      <h2>Was ist die englische Reimemaschine?</h2>
      <p>
        Mit der englischen Reimemaschine können Sie problemlos auf jedes
        beliebige Wort bis zu 500 englische Reime finden. Dabei ist es
        vollkommen egal, ob Sie ein deutsches oder ein englisches Wort eingeben.
        Die Ergebnisse sind allerdings alle auf englisch. Hier gelangen Sie zur{" "}
        <a href="/">deutschen Reimemaschine</a> .
      </p>
    </div>
  );
};

export default AboutEnglisch;

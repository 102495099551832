import React, { useEffect } from "react";

const Ad = (props) => {
  const { currentPath } = props;

  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }, [currentPath]);

  return (
    <div key={currentPath} style={{ marginBottom: "15px" }}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-8724263644048003"
        data-ad-slot="9433621757"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};

export default Ad;

import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Link,
  Route,
} from "react-router-dom";

// PAGES
import Home from "./pages/Home";
import Englisch from "./pages/Englisch";

import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/was-reimt-sich-auf-:id/">
            <Home />
          </Route>

          <Route path="/englisch/was-reimt-sich-auf-:id/">
            <Englisch />
          </Route>
          <Route path="/reimemaschine-englisch/">
            <Englisch />
          </Route>
          {/*REDIRECTS*/}
          <Route exact path="/doppel-reim-generator/">
            <Redirect to="/" />
          </Route>
          <Route exact path="/reim-generator/">
            <Redirect to="/" />
          </Route>
          <Route exact path="/reimlexikon/">
            <Redirect to="/" />
          </Route>
          <Route exact path="/rhyming-dictionary/">
            <Redirect to="/reimemaschine-englisch/" />
          </Route>
          <Route exact path="/raptexte-schreiben/">
            <Redirect to="/" />
          </Route>
          <Route exact path="/geburtstagsgedichte-gedichte-zum-geburtstag/">
            <Redirect to="/" />
          </Route>
          <Route exact path="/was-reimt-sich-auf/">
            <Redirect to="/" />
          </Route>
          <Route exact path="/was-reimt-sich-auf/auto/">
            <Redirect to="/was-reimt-sich-auf-auto/" />
          </Route>
          <Route exact path="/was-reimt-sich-auf/tag/">
            <Redirect to="/was-reimt-sich-auf-tag/" />
          </Route>
          <Route exact path="/sehr-geehrte-damen-und-herren/">
            <Redirect to="/" />
          </Route>

          <Route path="/impressum/">
            <Impressum />
          </Route>
          <Route path="/datenschutz/">
            <Datenschutz />
          </Route>

          {/*HOME*/}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>

      <footer>
        © 2021 - reimemaschine.org | Daten von{" "}
        <a
          style={{ textDecoration: "underline" }}
          href="https://rhymebrain.com/api.html"
        >
          RhymeBrain.com
        </a>{" "}
        <br />
        <a href="/impressum/">Impressum</a> |{" "}
        <a href="/datenschutz/">Datenschutz</a>
      </footer>
    </div>
  );
}

export default App;

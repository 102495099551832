import React, { useEffect, useState } from "react";
import db from "../firebase";

const Popular = () => {
  const [words, setWords] = useState([
    "orange",
    "zeit",
    "augen",
    "auf",
    "aus",
    "dabei",
    "tag",
    "dich",
    "feiern",
    "dir",
    "fest",
    "freude",
    "glück",
    "gehen",
    "hier",
    "haben",
    "gut",
    "jahr",
    "ist",
    "kommen",
    "leben",
    "liebe",
    "los",
    "machen",
    "mehr",
    "mir",
    "schön",
    "sehen",
    "sagen",
    "sein",
    "sonne",
    "spaß",
    "uns",
    "welt",
    "vergessen",
    "traurig",
    "werden",
    "zusammen",
    "vorbei",
  ]);

  const getPopular = () => {
    db.collection("popular").onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log(data[0].all);
      setWords(data[0].all);
    });
  };

  useEffect(() => {
    getPopular();
  }, []);

  return (
    <div className="popular">
      <h2>Häufig gesuchte Wörter</h2>
      <p>Oft in die Reimmaschine eingegeben:</p>
      <ul className="brackets">
        {words.map((word, index) => {
          return (
            <li>
              <a href={"/was-reimt-sich-auf-" + word + "/"}>{word}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Popular;

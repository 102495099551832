import firebase from 'firebase';
import 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCTw2spKL4Qoxw9INfi_L7uzT_v9ldDcXY",
    authDomain: "reimemaschine-react.firebaseapp.com",
    projectId: "reimemaschine-react",
    storageBucket: "reimemaschine-react.appspot.com",
    messagingSenderId: "1033016593144",
    appId: "1:1033016593144:web:8ad672a2057a14f405090d"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const db = firebaseApp.firestore();
  
  export default db;
import React from "react";

const About = () => {
  return (
    <div class="about">
      <h2>Was ist Reimemaschine?</h2>
      <p>
        Eine Reimmaschine bzw. Reimemaschine ist seit vielen Jahren ein
        beliebtes Online-Tool, mit dem man problemlos bis zu 1000 Reime auf ein
        bestimmtes Wort finden kann. Sie geben einfach ein beliebiges Wort in
        das Suchfeld ein, klicken anschließend auf "Reime finden" und es werden
        Ihnen alle Ergebnisse aufgelistet (absteigend nach Genauigkeit). Auch
        auf englisch verfügbar:{" "}
        <a href="/reimemaschine-englisch/">Reimemaschine Englisch</a>
      </p>
    </div>
  );
};

export default About;

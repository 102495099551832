import React from "react";
import { useParams } from "react-router-dom";
import About from "../partials/About";
import Popular from "../partials/Popular";
import Helmet from "react-helmet";

import Reimemaschine from "../partials/Reimemaschine";
import Ad from "../partials/Ad";

const Home = () => {
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>
          {!id
            ? "ᐅ Reimemaschine | Über 1.000.000 Reime finden [NEU]"
            : `ᐅ Was reimt sich auf ${id}? | Reimemaschine`}
        </title>

        <meta
          name="description"
          content={
            !id
              ? "Reimemaschine ✓ die Reime Suchmaschine, mit der du Reimwörter finden kannst. Wort eingeben, suchen und bis zu 500 Reime erhalten ★ 100% kostenlos!"
              : `Was reimt sich auf ${id}? Reimemaschine ✓ die Reime Suchmaschine, mit der du Reimwörter finden kannst. Wort eingeben, suchen und bis zu 500 Reime erhalten ★ 100% kostenlos!`
          }
        />
      </Helmet>

      <div className="content">
        <div className="content__inside">
          <span class="est">REIMEMASCHINE.ORG</span>
          <h1>
            <a title="Reimmaschine / Reimemaschine" href="/">
              REIMEMSCHINE
            </a>
          </h1>
          <p>Wort eingeben und bis zu 1000 Reime finden.</p>

          <div className="lang">
            <span className="ger active">
              <a href="/">
                <img
                  alt="Reimemaschine"
                  src="https://flagpedia.net/data/flags/emoji/apple/160x160/de.png"
                />
              </a>
            </span>
            <span className="en">
              <a href="/reimemaschine-englisch/">
                <img
                  alt="Reimemaschine Englisch"
                  src="https://flagpedia.net/data/flags/emoji/apple/160x160/gb.png"
                />
              </a>
            </span>
          </div>
          <Reimemaschine lang={"de"} suchwort={id?.replace(/-/g, " ")} />

          <Popular />
          <About />
        </div>
      </div>
    </>
  );
};

export default Home;

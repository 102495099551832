import React from "react";
import { useParams } from "react-router-dom";
import AboutEnglisch from "../partials/AboutEnglisch";
import Popular from "../partials/Popular";
import Helmet from "react-helmet";

import Reimemaschine from "../partials/Reimemaschine";

const Englisch = () => {
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>
          {!id
            ? "ᐅ Reimemaschine Englisch | Über 1.000.000 Reime finden [NEU]"
            : `ᐅ Was reimt sich auf ${id}? | Reimemaschine Englisch`}
        </title>

        <meta
          name="description"
          content={
            !id
              ? "Reimemaschine Englisch ✓ die englische Reime Suchmaschine, mit der du Reimwörter finden kannst. Wort eingeben, suchen und bis zu 500 englische Reime erhalten ★ 100% kostenlos!"
              : `Was reimt sich auf ${id}? Reimemaschine Englisch ✓ die englische Reime Suchmaschine, mit der du Reimwörter finden kannst. Wort eingeben, suchen und bis zu 500 englische Reime erhalten ★ 100% kostenlos!`
          }
        />
      </Helmet>
      <div className="ad"></div>
      <div className="content">
        <div className="content__inside">
          <span class="est">REIMEMASCHINE.ORG</span>
          <h1>
            <a
              title="Reimmaschine Englisch / Reimemaschine Englisch"
              href="/reimemaschine-englisch/"
            >
              REIMEMSCHINE
              <br />
              <span
                style={{
                  fontSize: "35px",
                  marginTop: "-6px",
                  opacity: "0.8",
                  display: "block",
                }}
              >
                ENGLISCH
              </span>
            </a>
          </h1>
          <p>Wort eingeben und englische Reime finden.</p>

          <div className="lang">
            <span className="ger active">
              <a href="/">
                <img
                  alt="Reimemaschine"
                  src="https://flagpedia.net/data/flags/emoji/apple/160x160/de.png"
                />
              </a>
            </span>
            <span className="en">
              <a href="/reimemaschine-englisch/">
                <img
                  alt="Reimemaschine Englisch"
                  src="https://flagpedia.net/data/flags/emoji/apple/160x160/gb.png"
                />
              </a>
            </span>
          </div>
          <Reimemaschine lang={"en"} suchwort={id?.replace(/-/g, " ")} />
          <Popular />
          <AboutEnglisch />
        </div>
      </div>
    </>
  );
};

export default Englisch;

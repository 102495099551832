import React, { useState, useRef, useEffect } from "react";
import db from "../firebase";
import Ad from "../partials/Ad";

const Reimemaschine = ({ suchwort, lang }) => {
  const [wort, setWort] = useState(suchwort);
  const [rhymes, setRhymes] = useState([]);
  const [results, setResults] = useState(null);
  const [catIndex, setCatIndex] = useState(0);

  const [oneSyll, setOneSyll] = useState([]);
  const [twoSyll, setTwoSyll] = useState([]);
  const [threeSyll, setThreeSyll] = useState([]);
  const [fourSyll, setFourSyll] = useState([]);

  const wortInput = useRef(null);

  const firebaseTest = () => {
    db.collection("words").onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data());
      const JSONDATA = JSON.parse(data[0].data);
      console.log(JSONDATA);
    });
  };

  const getRhymes = (wort) => {
    const searchFor = encodeURIComponent(wort);
    console.log(lang);
    const _apiURL = `https://rhymebrain.com/talk?function=getRhymes&word=${searchFor}&lang=${lang}&maxResults=1000`;

    fetch(_apiURL)
      .then((response) => response.json())
      .then((data) => {
        const newData = [{ word: wort }, ...data];
        newData.sort((a, b) => {
          return a.freq - b.freq;
        });
        console.log(newData);
        setResults(newData);
        filterBySyll(newData);

        console.timeEnd("API CALL");
        console.log(data);
      });
  };

  const filterBySyll = (data) => {
    const One = data.filter((item) => {
      return item.syllables <= 1;
    });

    const Two = data.filter((item) => {
      return item.syllables <= 2;
    });

    const Three = data.filter((item) => {
      return item.syllables <= 3;
    });

    const FourPlus = data.filter((item) => {
      return item.syllables >= 4;
    });

    console.log(One, Two, Three, FourPlus);
    setOneSyll(One);
    setTwoSyll(Two);
    setThreeSyll(Three);
    setFourSyll(FourPlus);
  };

  const locateToResults = (e) => {
    e.preventDefault();

    const wort = wortInput.current.value;
    if (wort && lang == "de") {
      window.location.href = "/was-reimt-sich-auf-" + wort + "/";
    }

    if (wort && lang == "en") {
      window.location.href = "/englisch/was-reimt-sich-auf-" + wort + "/";
    }
  };

  useEffect(() => {
    /*const url_string = window.location.href; //window.location.href
    const url = new URL(url_string);
    const wort = url.searchParams.get("wort");
    
  console.log(wort);*/

    console.time("API CALL");
    firebaseTest();
    if (wort) {
      getRhymes(wort);
      setWort(wort);

      wortInput.current.value = wort;
    } else {
    }

    //set syllables
    if (catIndex == 1) {
      setResults(oneSyll);
      console.log("eine silbe");
    }
  }, []);

  return (
    <form onSubmit={locateToResults} class="reimemaschine">
      <input
        ref={wortInput}
        placeholder={
          lang == "de" ? "Wort eingeben..." : "Englisches Wort eingeben..."
        }
        type="text"
        className="wort"
        name="wort"
        id=""
      />
      <svg
        role="img"
        aria-label="Suchwort für Reimmaschine"
        width="16"
        height="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.103 15.14l-4.972-4.972c-2.207 1.68-5.302 1.45-7.262-.51C-.288 7.5-.22 4.01 1.92 1.869c2.124-2.124 5.615-2.124 7.739 0 1.926 1.927 2.206 5.006.56 7.212l4.972 4.973-1.087 1.087zM8.571 2.957c-1.515-1.515-4.05-1.515-5.565 0-1.548 1.548-1.598 4.067-.05 5.615a4.005 4.005 0 0 0 5.664 0c1.548-1.548 1.499-4.067-.05-5.615z"
          fill="currentColor"
          fill-rule="evenodd"
        ></path>
        Reimemaschine: Reime finden
      </svg>

      <button type="button" onClick={locateToResults} className="finden">
        {lang == "de" ? "Reime finden" : "Englische Reime finden"}
      </button>

      <div className="results">
        {wort ? (
          <>
            <Ad currentPath={window.location.href} />
            <h2>
              Was reimt sich auf <strong>{wort}</strong>?
            </h2>
            <div className="category">
              <ul>
                <li>
                  <a
                    title="Zeige alle einsilbigen Reime"
                    onClick={() => {
                      setCatIndex(1);
                      setResults(oneSyll);
                    }}
                    className={catIndex == 1 ? "active" : ""}
                  >
                    1 Silbe
                  </a>
                </li>
                <li>
                  <a
                    title="Zeige alle zweisilbigen Reime"
                    onClick={() => {
                      setCatIndex(2);
                      setResults(twoSyll);
                    }}
                    className={catIndex == 2 ? "active" : ""}
                  >
                    2 Silben
                  </a>
                </li>
                <li>
                  <a
                    title="Zeige alle dreisilbigen Reime"
                    onClick={() => {
                      setCatIndex(3);
                      setResults(threeSyll);
                    }}
                    className={catIndex == 3 ? "active" : ""}
                  >
                    3 Silben
                  </a>
                </li>
                <li>
                  <a
                    title="Zeige alle viersilbigen Reime und mehr"
                    onClick={() => {
                      setCatIndex(4);
                      setResults(fourSyll);
                    }}
                    className={catIndex == 4 ? "active" : ""}
                  >
                    4+
                  </a>
                </li>
              </ul>
            </div>
            <p style={{ marginTop: "20px" }}>
              Auf <strong>{wort}</strong> reimen sich folgende Wörter
            </p>
          </>
        ) : (
          ""
        )}

        <ul className="results__list">
          {results ? (
            results.map((item, index) => {
              if (index < 30) {
                return (
                  <li key={index}>
                    <a
                      style={
                        item.flags?.includes("c")
                          ? { color: "black" }
                          : { color: "black" }
                      }
                      title={"Was reimt sich auf " + item.word + "?"}
                      href={
                        "/was-reimt-sich-auf-" +
                        item.word.replace(/-/g, " ").toLowerCase() +
                        "/"
                      }
                    >
                      {item.word}
                    </a>
                  </li>
                );
              } else {
                return <li key={index}>{item.word}</li>;
              }
            })
          ) : (
            <p
              style={!wort ? { display: "none" } : { display: "block" }}
              className="loading"
            >
              Daten werden geladen...
            </p>
          )}
        </ul>
      </div>
    </form>
  );
};

export default Reimemaschine;
